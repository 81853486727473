// import axios from 'axios';

// const { VUE_APP_AZURE_TRANSLATE_ENDPOINT, VUE_APP_AZURE_TRANSLATE_KEY } = process.env;
// const location = "global";
// const path = '/translate';
// const constructed_url = VUE_APP_AZURE_TRANSLATE_ENDPOINT + path;
// const headers = {
//     'Ocp-Apim-Subscription-Key': VUE_APP_AZURE_TRANSLATE_KEY,
//     'Ocp-Apim-Subscription-Region': location,
//     'Content-type': 'application/json',
// };

// async function translateTextToAllLang(text) {
//     if (!text || text == "") {
//         return '';
//     }

//     const params = {
//         'api-version': '3.0',
//         'from': 'he',
//         'to': ['en', 'ru', 'ar']
//     };

//     const body = [{
//         'text': text
//     }];

//     const response = await axios.post(constructed_url, body, {
//         params: params,
//         headers: headers
//     })

//     let res = response.data[0]?.translations;
//     res.unshift({ to: 'he', text: text });

//     return res;
// }

// async function translateFormattedTextToAllLang(formattedText) {
//     if (!formattedText || formattedText == "") {
//         return '';
//     }

//     const params = {
//         'api-version': '3.0',
//         'from': 'he',
//         'to': ['en', 'ru', 'ar']
//     };

//     const texts = formattedText.split(">");
//     var translatedTokensEn = [], translatedTokensRu = [], translatedTokensAr = [];
//     for (let i = 0; i < texts.length; i++){
//         const text = texts[i];
//         const tokens = text.split("<");
//         var translated = [];
//         if (tokens[0].length > 0) {
//             const body = [{
//                 'text': tokens[0]
//             }];

//             const response = await axios.post(constructed_url, body, {
//                 params: params,
//                 headers: headers
//             })

//             response.data[0].translations.forEach(e => {
//                 translated.push(e.text);
//             });
//         }

//         tokens[0] = translated[0] || "";
//         translatedTokensEn.push(tokens.join("<"));

//         tokens[0] = translated[1] || "";
//         translatedTokensRu.push(tokens.join("<"));

//         tokens[0] = translated[2] || "";
//         translatedTokensAr.push(tokens.join("<"));
//     }
//     const res = [
//         { to: 'he', text: formattedText },
//         { to: 'en', text: translatedTokensEn.join(">") },
//         { to: 'ru', text: translatedTokensRu.join(">") },
//         { to: 'ar', text: translatedTokensAr.join(">") },
//     ];

//     return res;
// }

// async function translateText(text, lang = 'he') {
//     if (lang === 'he') {
//         return text;
//     }

//     if (!text || text == "") {
//         return '';
//     }

//     const params = {
//         'api-version': '3.0',
//         'from': 'he',
//         'to': lang
//     };

//     const body = [{
//         'text': text
//     }];

//     const response = await axios.post(constructed_url, body, {
//         params: params,
//         headers: headers
//     })

//     return response.data[0]?.translations[0]?.text || text;
// }

// async function translateFormattedText(formattedText, lang='he') {
//     if (lang === 'he') {
//         return formattedText;
//     }

//     if (!formattedText || formattedText == "") {
//         return '';
//     }

//     const params = {
//         'api-version': '3.0',
//         'from': 'he',
//         'to': lang
//     };

//     const texts = formattedText.split(">");
//     var translatedTokens = [];
//     for (let i = 0; i < texts.length; i++){
//         const text = texts[i];
//         const tokens = text.split("<");
//         if (tokens[0].length > 0) {
//             const body = [{
//                 'text': tokens[0]
//             }];

//             const response = await axios.post(constructed_url, body, {
//                 params: params,
//                 headers: headers
//             })
//             tokens[0] = response.data[0]?.translations[0].text;
//         }
//         translatedTokens.push(tokens.join("<"));
//     }

//     const res = translatedTokens.join(">");
//     return res;
// }

async function translateTextToAllLang(text) {
  const res = [
    { to: 'he', text },
    { to: 'en', text },
    { to: 'ru', text },
    { to: 'ar', text },
  ];

  return res;
}

async function translateFormattedTextToAllLang(formattedText) {
  const res = [
    { to: 'he', text: formattedText },
    { to: 'en', text: formattedText },
    { to: 'ru', text: formattedText },
    { to: 'ar', text: formattedText },
  ];

  return res;
}

// eslint-disable-next-line no-unused-vars
async function translateText(text, lang = 'he') {
  return text;
}

// eslint-disable-next-line no-unused-vars
async function translateFormattedText(formattedText, lang = 'he') {
  return formattedText;
}

export { translateText, translateFormattedText, translateTextToAllLang, translateFormattedTextToAllLang };

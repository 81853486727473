const getOneLegsAirlines = (one) => one.legs.map((leg) => [leg.excerpt.airline, ...leg.segmentExcerpts.map((segment) => (segment.airline))]);

const getOneLegsAirports = (one) => {
  const legsAirports = one.legs.map((leg) => [leg.excerpt.departure.airport, leg.excerpt.arrival.airport]).flat();
  const segments = one.legs.map((leg) => leg.segmentExcerpts).flat();
  return legsAirports.concat(segments.map((segment) => segment.departure.airport))
    .concat(segments.map((segment) => segment.arrival.airport));
};

export {
  getOneLegsAirlines, getOneLegsAirports,
};

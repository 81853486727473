import sabreSearchStore from './modules/sabreSearch';
import sabreBookingStore from './modules/sabreBooking';

export default {
  modules: {
    sabreSearchStore,
    sabreBookingStore,
  },
  state: () => ({
    errorAlertMessage: '',
  }),
  getters: {
    GET_SABRE_ERROR_ALERT_MESSAGE: (state) => state.errorAlertMessage,
  },
  mutations: {
    SET_SABRE_ERROR_ALERT_MESSAGE: (state, payload) => {
      state.errorAlertMessage = payload;
    },
  },
  actions: {
  },
};

import {
  get,
  post,
  utf8ToB64,
  b64ToUtf8,
} from '@/utils/api';

import { getOneLegsAirlines, getOneLegsAirports } from '@/utils/sabreUtils/common';

const tMinutesPerDay = 24 * 60;

const { VUE_APP_LANGUAGES_LIST } = process.env;
const languageList = JSON.parse(VUE_APP_LANGUAGES_LIST);

export default {
  state: () => ({
    isOpenSearchDropdown: false,
    selectedSearchDirection: null,
    selectedSearchDepartments: [],
    isCheckedSearchDirectFlightOnly: false,
    isMultiCityDirectionState: false,
    chosenDestinations: {
      from: ['TLV'],
      to: [],
    },
    chosenMultiCityDestination: {
      from: [],
      to: [],
    },

    flightDirectionList: ['roundTrip', 'oneWay', 'multiCity'],
    calendarTypeList: ['exactDates', 'specificDateRange', 'fullMonthDateRange'],
    flightDepartmentList: [
      'Economy',
      'Business',
      'First',
      'PremiumEconomy',
    ],

    selectedCalendarType: '',
    selectedExactDateRange: [],
    selectedSpecificDateRange: [],
    selectedFullMonths: [],
    selectedNightsForSearch: 2,
    selectedWeekendTargeted: false,
    selectedMidweekTargeted: false,

    chosenAdultsForSearch: 2,
    chosenChildrenForSearch: 0,
    chosenBabiesForSearch: 0,
    chosenSeniorsForSearch: 0,

    currentSearchCondition: null,

    flightSearchResult: null,
    storedSearchResult: null,
    airlineCompanies: null,
    countries: null,
    airports: null,

    filteredFlightItems: [],
    itinGroups: [],
    brandFeatureDescs: [],
    baggageChargeDescs: [],

    filterConditions: null,
    searchQuery: '',
    changingFlightID: '',
    changingFlightItem: null,

  }),
  getters: {
    GET_OPEN_STATE_SEARCH_DROPDOWN: (state) => state.isOpenSearchDropdown,

    GET_SABRE_IS_CHECKED_DIRECT_FLIGHTS_ONLY: (state) => state.isCheckedSearchDirectFlightOnly,
    GET_SABRE_IS_MULTI_CITY_STATE: (state) => state.isMultiCityDirectionState,
    GET_SABRE_CHOSEN_DESTINATIONS: (state) => state.chosenDestinations,
    GET_SABRE_CHOSEN_MULTI_DESTINATIONS: (state) => state.chosenMultiCityDestination,

    GET_SABRE_FLIGHT_DIRECTION_LIST: (state) => state.flightDirectionList,
    GET_SABRE_SELECTED_FLIGHT_DIRECTION: (state) => state.selectedSearchDirection,
    GET_SABRE_FLIGHT_DEPARTMENT_LIST: (state) => state.flightDepartmentList,
    GET_SABRE_SELECTED_FLIGHT_DEPARTMENTS: (state) => state.selectedSearchDepartments,

    GET_SABRE_CALENDAR_TYPE_LIST: (state) => state.calendarTypeList,
    GET_SABRE_CALENDAR_TYPE: (state) => state.selectedCalendarType,
    GET_SABRE_EXACT_DATE_RANGE: (state) => state.selectedExactDateRange,
    GET_SABRE_SPECIFIC_DATE_RANGE: (state) => state.selectedSpecificDateRange,
    GET_SABRE_FULL_MONTHS: (state) => state.selectedFullMonths,
    GET_SABRE_NIGHTS_FOR_SEARCH: (state) => state.selectedNightsForSearch,
    GET_SABRE_WEEKEND_TARGETED_FOR_SEARCH: (state) => state.selectedWeekendTargeted,
    GET_SABRE_MIDWEEK_TARGETED_FOR_SEARCH: (state) => state.selectedMidweekTargeted,

    GET_SABRE_ADULTS_FOR_SEARCH: (state) => state.chosenAdultsForSearch,
    GET_SABRE_CHILDREN_FOR_SEARCH: (state) => state.chosenChildrenForSearch,
    GET_SABRE_BABIES_FOR_SEARCH: (state) => state.chosenBabiesForSearch,
    GET_SABRE_SENIORS_FOR_SEARCH: (state) => state.chosenSeniorsForSearch,

    GET_SABRE_CURRENT_SEARCH_CONDITION: (state) => state.currentSearchCondition,

    GET_SABRE_AIRLINE_COMPANIES: (state) => state.airlineCompanies,
    GET_SABRE_COUNTRIES: (state) => state.countries,
    GET_SABRE_AIRPORTS: (state) => state.airports,

    GET_SABRE_FLIGHT_SEARCH_RESULT: (state) => state.flightSearchResult,
    GET_SABRE_FLIGHT_FILTERED_ITEMS: (state) => state.filteredFlightItems,

    GET_SABRE_FILTER_CONDITIONS: (state) => state.filterConditions,
    GET_SABRE_FLIGHT_ITIN_GROUPS: (state) => state.itinGroups,
    GET_SABRE_FLIGHT_BRAND_FEATURES_DESC: (state) => state.brandFeatureDescs,
    GET_SABRE_FLIGHT_BAGGAGE_CHARGE_DESC: (state) => state.baggageChargeDescs,
    GET_SABRE_FLIGHT_SEARCH_QUERY: (state) => state.searchQuery,

    GET_SABRE_CHANGING_FLIGHT_ITEM: (state) => state.changingFlightItem,
  },
  mutations: {
    SET_OPEN_STATE_SEARCH_DROPDOWN: (state, payload) => {
      state.isOpenSearchDropdown = payload;
    },
    SET_SABRE_SELECTED_FLIGHT_DIRECTION: (state, payload) => {
      state.selectedSearchDirection = payload;
      state.isMultiCityDirectionState = payload === 'multiCity';
    },
    SET_SABRE_FLIGHT_DEPARTMENT: (state, payload) => {
      const departments = [...state.selectedSearchDepartments];
      const { all, item } = payload;

      if (all) {
        state.selectedSearchDepartments = [...item];
        return;
      }

      const inx = departments.indexOf(item);
      if (inx === -1) {
        departments.push(item);
      } else {
        departments.splice(inx, 1);
      }
      state.selectedSearchDepartments = [...departments];
    },
    SET_SABRE_IS_CHECKED_DIRECT_FLIGHT_ONLY: (state, payload) => {
      state.isCheckedSearchDirectFlightOnly = payload;
    },
    SET_SABRE_CHOOSE_DESTINATION: (state, payload) => {
      const { from, code, clean, isMultiCity } = payload;
      const oldData = isMultiCity ? { ...state.chosenMultiCityDestination } : { ...state.chosenDestinations };
      if (clean) {
        if (isMultiCity) {
          state.chosenMultiCityDestination = {
            from: (from) ? [] : oldData.from,
            to: (from) ? oldData.to : [],
          };
        } else {
          state.chosenDestinations = {
            from: (from) ? [] : oldData.from,
            to: (from) ? oldData.to : [],
          };
        }
        return;
      }

      const chosen = (from) ? [...oldData.from] : [...oldData.to];
      const inx = chosen.indexOf(code);

      if (inx === -1) {
        chosen.push(code);
      } else {
        chosen.splice(inx, 1);
      }

      if (isMultiCity) {
        state.chosenMultiCityDestination = {
          from: (from) ? chosen : oldData.from,
          to: (from) ? oldData.to : chosen,
        };
      } else {
        state.chosenDestinations = {
          from: (from) ? chosen : oldData.from,
          to: (from) ? oldData.to : chosen,
        };
      }
    },

    SET_SABRE_CALENDAR_TYPE: (state, payload) => {
      state.selectedCalendarType = payload;
    },
    SET_SABRE_EXACT_DATE_RANGE: (state, payload) => {
      state.selectedExactDateRange = payload;
    },
    SET_SABRE_SPECIFIC_DATE_RANGE: (state, payload) => {
      state.selectedSpecificDateRange = payload;
    },
    SET_SABRE_FULL_MONTHS: (state, payload) => {
      const origin = [...state.selectedFullMonths];
      const { all, item } = payload;

      if (all) {
        state.selectedFullMonths = [...item];
        return;
      }

      const inx = origin.indexOf(item);
      if (inx === -1) {
        origin.push(item);
      } else {
        origin.splice(inx, 1);
      }
      origin.sort((a, b) => (new Date(a) - new Date(b)));
      state.selectedFullMonths = [...origin];
    },
    SET_SABRE_NIGHTS_FOR_SEARCH: (state, payload) => {
      state.selectedNightsForSearch = payload;
    },
    SET_SABRE_WEEKEND_TARGETED_FOR_SEARCH: (state, payload) => {
      state.selectedWeekendTargeted = payload;
    },
    SET_SABRE_MIDWEEK_TARGETED_FOR_SEARCH: (state, payload) => {
      state.selectedMidweekTargeted = payload;
    },

    SET_SABRE_ADULTS_FOR_SEARCH: (state, payload) => {
      state.chosenAdultsForSearch = payload || 0;
    },
    SET_SABRE_CHILDREN_FOR_SEARCH: (state, payload) => {
      state.chosenChildrenForSearch = payload || 0;
    },
    SET_SABRE_BABIES_FOR_SEARCH: (state, payload) => {
      state.chosenBabiesForSearch = payload || 0;
    },
    SET_SABRE_SENIORS_FOR_SEARCH: (state, payload) => {
      state.chosenSeniorsForSearch = payload || 0;
    },
    SET_SABRE_CURRENT_SEARCH_CONDITION: (state, payload) => {
      state.currentSearchCondition = payload;
    },

    SET_SABRE_FLIGHT_SEARCH_RESULT: (state, payload) => {
      state.flightSearchResult = payload;
    },

    SET_SABRE_FLIGHT_STORED_SEARCH_RESULT: (state, payload) => {
      state.storedSearchResult = payload;
    },

    SET_SABRE_AIRLINE_COMPANIES: (state, payload) => {
      state.airlineCompanies = payload;
    },
    SET_SABRE_COUNTRIES: (state, payload) => {
      state.countries = payload;
    },
    SET_SABRE_AIRPORTS: (state, payload) => {
      state.airports = payload;
    },

    SET_SABRE_FLIGHT_FILTERED_ITEMS: (state, payload) => {
      state.filteredFlightItems = payload;
    },
    SET_SABRE_FILTER_CONDITIONS: (state, payload) => {
      const { type, conditions, key, value } = payload;
      if (type === 'all') {
        state.filterConditions = conditions;
      } else {
        const cond = { ...state.filterConditions };
        cond[key] = value;
        state.filterConditions = cond;
      }
    },

    SET_SABRE_FLIGHT_ITIN_GROUPS: (state, payload) => {
      state.itinGroups = payload;
    },
    SET_SABRE_FLIGHT_BRAND_FEATURES_DESC: (state, payload) => {
      state.brandFeatureDescs = payload;
    },
    SET_SABRE_FLIGHT_BAGGAGE_CHARGE_DESC: (state, payload) => {
      state.baggageChargeDescs = payload;
    },
    SET_SABRE_FLIGHT_SEARCH_QUERY: (state, payload) => {
      state.searchQuery = payload;
    },
    SET_SABRE_CHANGE_FLIGHT_ID: (state, payload) => {
      state.changingFlightID = payload;
    },
    SET_SABRE_CHANGING_FLIGHT_ITEM: (state, payload) => {
      state.changingFlightItem = payload;
    },
  },
  actions: {
    FETCH_SABRE_FLIGHT_SEARCH_RESULT: async (context, payload) => {
      const url = '/operation/aggrSabre/search?excerptOnly=true&sendRaw=false';
      const { calendarTypeList, storedSearchResult, searchQuery } = context.state;
      const destinationInfo = context.state.selectedSearchDirection === 'multiCity' ? [
        {
          originCodes: context.state.chosenDestinations.from,
          destinationCodes: context.state.chosenDestinations.to,
        },
        {
          originCodes: context.state.chosenMultiCityDestination.from,
          destinationCodes: context.state.chosenMultiCityDestination.to,
        },
      ] : [
        {
          originCodes: context.state.chosenDestinations.from,
          destinationCodes: context.state.chosenDestinations.to,
        },
      ];
      const exactDateInfo = context.state.selectedCalendarType === calendarTypeList[0] ? {
        outboundDepartureDate: context.state.selectedExactDateRange[0],
        inboundDepartureDate: context.state.selectedExactDateRange[1],
      } : null;
      const specDateInfo = context.state.selectedCalendarType === calendarTypeList[1] ? {
        startDate: context.state.selectedSpecificDateRange[0],
        endDate: context.state.selectedSpecificDateRange[1],
        nights: context.state.selectedNightsForSearch,
        weekendTargeted: context.state.selectedWeekendTargeted,
        MidweekTargeted: context.state.selectedMidweekTargeted,
      } : null;
      const fullMonthDateInfo = context.state.selectedCalendarType === calendarTypeList[2] ? {
        yearMonths: [...context.state.selectedFullMonths],
        nights: context.state.selectedNightsForSearch,
        weekendTargeted: context.state.selectedWeekendTargeted,
        MidweekTargeted: context.state.selectedMidweekTargeted,
      } : null;

      const requestBody = {
        flightDirection: context.state.selectedSearchDirection,
        originDestinationInformation: destinationInfo,
        calendarType: context.state.selectedCalendarType,
        exactDatesInformation: exactDateInfo,
        specificDateRangeInformation: specDateInfo,
        fullMonthDateRangeInformation: fullMonthDateInfo,
        passengerComposition: {
          adults: context.state.chosenAdultsForSearch,
          children: context.state.chosenChildrenForSearch,
          infants: context.state.chosenBabiesForSearch,
          seniors: context.state.chosenSeniorsForSearch,
        },
        directFlightsOnly: context.state.isCheckedSearchDirectFlightOnly,
        cabinPrefs: [...context.state.selectedSearchDepartments],
      };

      context.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', []);
      if (storedSearchResult && searchQuery === payload) {
        /**
         * When search with same query body, let use stored search result
         * This is used when come back to the search result page from the change flight/booking page.
         */
        context.commit('SET_SABRE_FLIGHT_SEARCH_RESULT', storedSearchResult);
        context.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', storedSearchResult.itineraries || []);
        context.commit('SET_SABRE_FLIGHT_ITIN_GROUPS', storedSearchResult.itinGroups || []);
        return storedSearchResult;
      } else {
        context.commit('SET_SABRE_FLIGHT_ITIN_GROUPS', []);
        context.commit('SET_SABRE_FLIGHT_BRAND_FEATURES_DESC', []);
        context.commit('SET_SABRE_FLIGHT_BAGGAGE_CHARGE_DESC', []);
        context.commit('SET_SABRE_CURRENT_SEARCH_CONDITION', null);
        /**
         * The search with new query body
         */
        try {
          context.commit('SET_SABRE_FLIGHT_SEARCH_QUERY', payload);
          context.commit('SET_LOADING_STATE', true);
          const response = await post('BFF', url, requestBody, context);

          if (response?.data?.error?.message) {
            context.commit('SET_SABRE_ERROR_ALERT_MESSAGE', response?.data?.error?.message || '');
          } else if (response?.data?.data) {
            context.commit('SET_SABRE_FLIGHT_SEARCH_RESULT', response?.data?.data);
            /**
             * The template code to test booking code diff.
             */
            // console.log(response?.data?.data?.itineraries.map(
            //   (itin) => {
            //     const a = itin.pricingInformation?.map(
            //       (pricing) => pricing.fare?.passengerInfoList?.map(
            //         (pass) => pass.fareComponents?.map(
            //           (fare) => fare.segments?.map(
            //             (seg) => seg.bookingCode,
            //           ),
            //         ),
            //       ).flat(Infinity),
            //     );
            //     const b = {};
            //     b[itin.id] = a;
            //     return b;
            //   },
            // ));
            context.commit('SET_SABRE_FLIGHT_STORED_SEARCH_RESULT', response?.data?.data);
            context.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', response?.data?.data?.itineraries || []);

            context.commit('SET_SABRE_FLIGHT_ITIN_GROUPS', response?.data?.data?.itinGroups || []);
            context.commit('SET_SABRE_FLIGHT_BRAND_FEATURES_DESC', response?.data?.data?.brandFeatureDescs || []);
            context.commit('SET_SABRE_FLIGHT_BAGGAGE_CHARGE_DESC', response?.data?.data?.baggageChargeDescs || []);
            context.commit('SET_SABRE_CURRENT_SEARCH_CONDITION', requestBody);
          }

          context.commit('SET_LOADING_STATE', false);
          return response;
        } catch (error) {
          /**
           * If when call the search endpoint then arise an error, send an error email.
           */
          const body = `<p>${JSON.stringify(payload)}</p>
          <h3>${url}</h3></Br>
          <pre>${error.response.data.title || error.response.data}</pre>`;
          const notification = {
            subject: `Sabre flight search result - ${error.response.status}`,
            body,
          };
          context.dispatch('NOTIFY_ADMIN', notification);
          context.commit('SET_LOADING_STATE', false);
          return null;
        }
      }
    },
    EXTRACT_SABRE_FLIGHT_FILTER_CONDITION: (context) => {
      const { flightSearchResult, airlineCompanies, airports, selectedSearchDirection, flightDirectionList,
        chosenAdultsForSearch, chosenChildrenForSearch, chosenBabiesForSearch, chosenSeniorsForSearch, changingFlightItem } = context.state;
      const conditionData = {
        type: 'all',
        conditions: null,
      };
      const persons = +chosenAdultsForSearch + +chosenChildrenForSearch + +chosenBabiesForSearch + +chosenSeniorsForSearch;
      const isOneWay = selectedSearchDirection === flightDirectionList[1] || changingFlightItem;
      const resultList = flightSearchResult?.itineraries || [];

      if (!resultList.length) {
        context.commit('SET_SABRE_FILTER_CONDITIONS', conditionData);
      } else {
        const conditions = {};
        conditions.stopsFilterState = [0, 1, 2];

        conditions.isOneWay = isOneWay;
        conditions.persons = persons;

        conditions.maxDuration1 = Math.max(...resultList.map((item) => Math.floor(item.legs[0].excerpt.elapsedTime / 60))) + 1;
        conditions.maxDuration2 = (isOneWay) ? 0 : Math.max(...resultList.map((item) => Math.floor(item.legs[1].excerpt.elapsedTime / 60))) + 1;
        conditions.durFilterValue1 = conditions.maxDuration1;
        conditions.durFilterValue2 = conditions.maxDuration2;

        // getting airline list
        const flightAirlines = resultList.map((flight) => getOneLegsAirlines(flight)).flat().flat();
        const airlineCodeList = [...new Set(flightAirlines)];
        conditions.airlineList = airlineCodeList.map((code) => ({ code, name: airlineCompanies?.find((c) => c.barcode === code)?.desc1 || code }));
        conditions.selectedAirline = conditions.airlineList.map((line) => line.code);

        // getting airport list
        const flightAirports = resultList.map((flight) => getOneLegsAirports(flight)).flat();
        const airportCodeList = [...new Set(flightAirports)];
        conditions.airportList = airportCodeList.map((code) => ({
          code,
          name: airports?.find((c) => c.code === code)?.nameEn || code,
        }));
        conditions.selectedAirport = conditions.airportList.map((port) => port.code);

        conditions.departureTimeFilter = [0, tMinutesPerDay];
        conditions.arrivalTimeFilter = [0, tMinutesPerDay];

        // getting max budget
        const totalPrices = resultList.map((item) => Math.floor(item.excerpt.totalPrice / persons));
        const budgetStep = 10;
        conditions.budgetStep = budgetStep;
        conditions.maxBudget = Math.max(...totalPrices) + budgetStep;
        conditions.minBudget = Math.min(...totalPrices) - budgetStep;
        conditions.budgetDuration = [conditions.minBudget, conditions.maxBudget];

        conditions.filterDisabled = false;

        conditionData.conditions = conditions;
        context.commit('SET_SABRE_FILTER_CONDITIONS', conditionData);
      }
    },
    FETCH_SABRE_AIRLINE_COMPANIES: async (context) => {
      const url = '/lkAirlineCompany?from=1&to=1000&langCode=HEB';
      try {
        const response = await get('BFF', url, context);
        context.commit('SET_SABRE_AIRLINE_COMPANIES', response?.data?.data || null);
        return response;
      } catch (error) {
        return null;
      }
    },
    FETCH_SABRE_COUNTRIES: async (context) => {
      const langCode = languageList[context.rootState.language];
      const url = `/country/?from=1&to=1000&langCode=${langCode}`;
      try {
        const response = await get('BFF', url, context);
        context.commit('SET_SABRE_COUNTRIES', response?.data?.data || null);
        return response;
      } catch (error) {
        return null;
      }
    },
    FETCH_SABRE_AIRPORTS: async (context) => {
      const url = '/airport/?from=1&to=1000';
      try {
        const response = await get('BFF', url, context);
        context.commit('SET_SABRE_AIRPORTS', response?.data?.data || null);
        return response;
      } catch (error) {
        return null;
      }
    },
    SABRE_ORDER_FLIGHT: (context, payload) => {
      const { storedSearchResult, currentSearchCondition, itinGroups, searchQuery, brandFeatureDescs, baggageChargeDescs } = context.state;
      const { flightId } = payload;
      const orderedFlight = storedSearchResult?.itineraries?.find((flight) => flight.id === flightId) || null;
      const itinInfo = orderedFlight ? itinGroups.find((itin) => itin.id === orderedFlight.itinGroupRef) : null;
      if (itinInfo) {
        orderedFlight.itinInfo = itinInfo;
      }

      window.localStorage.setItem('sabreOrderedFlight', utf8ToB64(JSON.stringify(orderedFlight)));

      window.localStorage.setItem('sabreBrandFeatureDescs', utf8ToB64(JSON.stringify(brandFeatureDescs)));
      window.localStorage.setItem('sabreBaggageChargeDescs', utf8ToB64(JSON.stringify(baggageChargeDescs)));

      window.localStorage.setItem('sabreSearchCondition', utf8ToB64(JSON.stringify(currentSearchCondition)));
      window.localStorage.setItem('sabreSearchQuery', utf8ToB64(searchQuery));
    },
    SABRE_CHANGE_FLIGHT: (context, payload) => {
      const { flightSearchResult, itinGroups } = context.state;
      const { flightId } = payload;
      const changingFlight = flightSearchResult?.itineraries?.find((flight) => flight.id === flightId) || null;
      const itinInfo = changingFlight ? itinGroups.find((itin) => itin.id === changingFlight.itinGroupRef) : null;
      if (itinInfo) {
        changingFlight.itinInfo = itinInfo;
      }

      /**
       * Because client can refresh the change flight page, the selected flight must be stored in storage
       */
      window.localStorage.setItem('sabreChangingFlight', utf8ToB64(JSON.stringify(changingFlight)));
      context.commit('SET_SABRE_CHANGING_FLIGHT_ITEM', changingFlight);
    },
    FETCH_SABRE_CHANGE_FLIGHTS_LIST: (context, payload) => {
      const changeFlightID = payload;
      const { storedSearchResult, changingFlightItem } = context.state;
      context.commit('SET_SABRE_CHANGE_FLIGHT_ID', changeFlightID);
      /**
       * When refresh the change flight page, the selected flight take out from localStorage.
       */
      if (!changingFlightItem) {
        const flight = window.localStorage.getItem('sabreChangingFlight');
        const orderedFlight = flight ? JSON.parse(b64ToUtf8(flight)) : null;
        context.commit('SET_SABRE_CHANGING_FLIGHT_ITEM', orderedFlight);
      }

      const { itineraries, ...remainData } = storedSearchResult;
      const returnFlightExtracted = itineraries.filter((elm) => elm.legs[0].ref === context.state.changingFlightItem.legs[0].ref).map((element) => {
        const { legs, ...newObj } = element;
        newObj.legs = [legs[1]];
        return newObj;
      });

      const searchResult = { ...remainData };
      searchResult.itineraries = returnFlightExtracted;

      context.commit('SET_SABRE_FLIGHT_SEARCH_RESULT', searchResult);
      context.commit('SET_SABRE_FLIGHT_FILTERED_ITEMS', searchResult.itineraries || []);
    },
    SABRE_REMOVE_ALL_STORED_DATA: () => {
      window.localStorage.removeItem('sabreOrderedFlight');
      window.localStorage.removeItem('sabreBrandFeatureDescs');
      window.localStorage.removeItem('sabreBaggageChargeDescs');
      window.localStorage.removeItem('sabreSearchCondition');
      window.localStorage.removeItem('sabreSearchQuery');
      window.localStorage.removeItem('sabreChangingFlight');

      window.localStorage.removeItem('sabrePassengerData');
      window.localStorage.removeItem('sabreServiceData');
      window.localStorage.removeItem('sabrePayerConditionInfo');
      window.localStorage.removeItem('sabrePaymentUrlInfo');
    },
  },
};

const { VUE_APP_BONAUF_DOMAIN } = process.env;

export default {
  methods: {
    gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    },
    availableEmitEventGA4ByDomain() {
      let availableEmit = false;
      const currDomain = window.location.host;
      if (currDomain.includes(VUE_APP_BONAUF_DOMAIN)
        || (!currDomain.includes(VUE_APP_BONAUF_DOMAIN) && !currDomain.includes('vercel'))
      ) {
        availableEmit = true;
      }
      return availableEmit;
    },
  },
};

import {
  get,
} from '@/utils/api';

import {
  getFilteredDeals,
  estimationAvailableSubcategory,
  getDestOptionsDealPackages,
  getMonthOptionsDealPackages,
  getCategoryOptionsDealPackages,
  getRangePriceDeals,
  getBatchDealPerHotel,
  getSortedDealsPerHotel,
  getAppDealsUnderCategory,
  getActiveSubcategories,
} from '@/utils/bonauf/controllerDealFilter';

import {
  getNameDestination,
  getNameCategory,
} from '@/utils/bonauf/controllerCommon';

const { VUE_APP_BONAUF_DOMAIN } = process.env;

export default {
  state: {
    appDealBanner: null,
    filterOptions: {
      destination: null,
      category: null,
      month: null,
      fromPrice: 0,
      toPrice: 100000,
    },
    dealCategoryOptions: {
      category: null,
      subCategory: null,
    },
    appDealPackages: null,
    appDealMenu: [],
    filteredAppDeals: [],

    specificAppDeal: null,

    detailItinerary: {},

    availableSubcategoryDeals: false,
    appDealSubcategory: [],
  },
  getters: {
    GET_BONAUF_DESKTOP_APP: (state, getters, rootState) => {
      const { host } = window.location;
      return rootState.isAppDealPage && host.includes(VUE_APP_BONAUF_DOMAIN);
    },
    GET_APP_DEAL_BANNER: (state) => state.appDealBanner?.banner ?? null,
    GET_OPTIONS_DEST_APP_DEAL: (state) => getDestOptionsDealPackages(state.filteredAppDeals, state.appDealPackages),
    GET_OPTIONS_MONTH_APP_DEAL: (state) => getMonthOptionsDealPackages(state.filteredAppDeals, state.appDealPackages),
    GET_OPTIONS_CATE_APP_DEAL: (state) => getCategoryOptionsDealPackages(state.filteredAppDeals, state.appDealMenu),
    GET_RANGE_PRICE: (state) => getRangePriceDeals(state.filteredAppDeals, state.appDealPackages),
    GET_MENU_ITEMS_APP_DEAL: (state) => state.appDealMenu,
    GET_FILTER_OPTION: (state) => state.filterOptions,
    GET_BATCH_DEAL_PER_HOTEL: (state) => getBatchDealPerHotel(state.filteredAppDeals),
    GET_SORTED_DEALS_PER_HOTEL: (state) => getSortedDealsPerHotel(state.filteredAppDeals),
    GET_FILTERED_APP_DEALS: (state) => state.filteredAppDeals,

    GET_SPECIFIC_APP_DEAL: (state) => state.specificAppDeal,

    GET_ITINERARY_DETAIL: (state) => state.detailItinerary,

    GET_DEST_NAME: (state) => (pDestCode) => getNameDestination(pDestCode, state.appDealPackages?.filters?.dest),
    GET_CATEGORY_NAME: (state) => (pCateCode) => getNameCategory(pCateCode, state.appDealMenu),

    GET_AVAILABLE_SUBCATEGORY: (state) => state.availableSubcategoryDeals,
    GET_APP_DEAL_SUBCATEGORY: (state) => state.appDealSubcategory,
    GET_ACTIVE_SUBCATEGORIES: (state) => (pCateCode) => getActiveSubcategories(pCateCode, state.appDealMenu, state.appDealPackages, state.appDealSubcategory),
  },
  mutations: {
    SET_APP_DEAL_BANNER: (state, payload) => {
      state.appDealBanner = payload;
    },
    SET_APP_DEAL_PACKAGES: (state, payload) => {
      state.appDealPackages = payload;
    },
    SET_APP_DEAL_SUBCATEGORY: (state, payload) => {
      state.appDealSubcategory = payload;
    },
    SET_APP_DEAL_MENU: (state, payload) => {
      state.appDealMenu = payload;
    },
    SET_APP_DEALS_FILTERED: (state, payload) => {
      state.filteredAppDeals = payload;
    },
    SET_FILTER_OPTION: (state, payload) => {
      state.filterOptions = payload;
      state.filteredAppDeals = getFilteredDeals(state.appDealPackages, payload);
      state.availableSubcategoryDeals = estimationAvailableSubcategory(state.appDealPackages, state.appDealSubcategory, payload);
    },
    SET_SPECIFIC_APP_DEAL: (state, payload) => {
      state.specificAppDeal = payload;
    },
    SET_APP_DEAL_CATEGORY_OPTIONS: (state, payload) => {
      state.dealCategoryOptions = payload;
      state.filteredAppDeals = getAppDealsUnderCategory(state.appDealPackages, payload);
    },
    SET_ITINERARY_DETAIL: (state, payload) => {
      state.detailItinerary = { ...state.detailItinerary, ...payload };
    },
  },
  actions: {
    FETCH_APP_DEAL_BANNER: async (context) => {
      context.commit('SET_LOADING_STATE', true);
      try {
        const response = await get('BFF', '/app/banner', context);
        context.commit('SET_APP_DEAL_BANNER', response.data);
        context.commit('SET_LOADING_STATE', false);
      } catch (error) {
        context.commit('SET_LOADING_STATE', false);
        console.log(error);
      }
    },
    FETCH_APP_DEAL_PACKAGES: async (context) => {
      context.commit('SET_LOADING_STATE', true);
      try {
        if (!context.state.appDealPackages) {
          const response = await get('BFF', '/app/getPackagesNew?state=APP&newVersion=true', context);
          context.commit('SET_APP_DEAL_PACKAGES', response.data);
          context.commit('SET_APP_DEALS_FILTERED', getFilteredDeals(response.data, context.state.filterOptions));
        }
        context.commit('SET_LOADING_STATE', false);
      } catch (error) {
        context.commit('SET_LOADING_STATE', false);
        console.log(error);
      }
    },
    FETCH_APP_DEAL_MENU: async (context) => {
      try {
        if (context.state.appDealMenu.length === 0) {
          const response = await get('BFF', '/app/getMenuNew?newVersion=true', context);
          context.commit('SET_APP_DEAL_MENU', response.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    FETCH_APP_DEAL_SUBCATEGORY: async (context) => {
      try {
        if (context.state.appDealSubcategory.length === 0) {
          const response = await get('BFF', '/app/dealSubCategory/all', context);
          context.commit('SET_APP_DEAL_SUBCATEGORY', response.data);
        }
      } catch (error) {
        console.log(error);
      }
    },
    FETCH_APP_DEAL_SPECIFIC: async (context, payload) => {
      context.commit('SET_LOADING_STATE', true);
      const { id } = payload;
      try {
        const response = await get('BFF', `/app/getPackageData?id=${id}&newVersion=true`, context);
        context.commit('SET_SPECIFIC_APP_DEAL', response.data);
      } catch (error) {
        context.commit('SET_LOADING_STATE', false);
        console.log(error);
      }
    },
    FETCH_ITINERARY_DETAIL: async (context, payload) => {
      const { id } = payload;
      try {
        if (context.state.detailItinerary[id] === undefined) {
          const response = await get('BFF', `/app/itineraryDetail?dealid=${id}`, context);
          context.commit('SET_ITINERARY_DETAIL', { [id]: response.data });
          return response.data;
        } else {
          return context.state.detailItinerary[id];
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    },
  },
};

/* eslint-disable no-shadow */
import {
  get,
  // post,
  // postWithText,
  // cancelGetRequest,
} from '@/utils/api';

const state = {
  destinationCodes: null,
  fromDate: null,
  toDate: null,
  currentSearchCategory: null,
  adult: 2,
  child: 0,
  infant: 0,
  pensioner: 0,

  yearMonth: null,

  sltFHSubcategoryList: null,
  subCategories: null,
};

const getters = {
  GET_FH_CHOSEN_DESTINATIONS: (state) => state.destinationCodes,
  GET_FH_FROM_DATE: (state) => state.fromDate,
  GET_FH_TO_DATE: (state) => state.toDate,
  GET_FH_ADULTS: (state) => state.adult,
  GET_FH_CHILDREN: (state) => state.child,
  GET_FH_INFANTS: (state) => state.infant,
  GET_FH_PENSIONERS: (state) => state.pensioner,
  GET_FH_YEAR_MONTHS: (state) => state.yearMonth,

  GET_FH_SELECTED_SUB_CATE: (state) => state.sltFHSubcategoryList,
  GET_FH_SUB_CATEGORY_LIST: (state) => state.subCategories,
};

const mutations = {
  SET_CHOSEN_DESTINATIONS: (state, payload) => {
    state.destinationCodes = payload;
  },
  SET_FROM_TO_DATE: (state, payload) => {
    state.fromDate = payload?.from || null;
    state.toDate = payload?.to || null;
  },
  SET_FH_ADULTS: (state, payload) => {
    state.adult = +payload;
  },
  SET_FH_CHILDREN: (state, payload) => {
    state.child = +payload;
  },
  SET_FH_INFANTS: (state, payload) => {
    state.infant = +payload;
  },
  SET_FH_PENSIONERS: (state, payload) => {
    state.pensioner = +payload;
  },
  SET_FH_YEAR_MONTHS: (state, payload) => {
    state.yearMonth = payload;
  },

  SET_FH_SELECTED_SUB_CATE: (state, payload) => {
    if (!payload) {
      state.sltFHSubcategoryList = null;
      return;
    }
    const list = state.sltFHSubcategoryList ? [...state.sltFHSubcategoryList] : [];
    const inx = list.findIndex((ids) => (ids === payload));
    if (inx > -1) {
      list.splice(inx, 1);
    } else {
      list.push(payload);
    }
    state.sltFHSubcategoryList = list;
  },
  SET_FH_SUB_CATEGORIES: (state, payload) => {
    state.subCategories = payload;
  },
};

const actions = {
  FETCH_CALENDAR_DATA_FROM_DEST: (context, payload) => {
    const destCodeArray = [...payload.dest];
    const { category } = payload;
    context.commit('SET_CHOSEN_DESTINATIONS', destCodeArray);
    context.commit('SET_FROM_TO_DATE', { from: null, to: null });
    const categorySubjectIdList = category.subjectCodes.map(
      (item) => item,
    );

    context.dispatch('FETCH_CALENDAR_DATA', { dest: destCodeArray, subjects: categorySubjectIdList });
  },
  RESET_FH_SEARCH_CONDITION: (context) => {
    context.commit('SET_CHOSEN_DESTINATIONS', []);
    context.commit('SET_FROM_TO_DATE', { from: null, to: null });
    context.commit('SET_FH_YEAR_MONTHS', null);
    context.commit('SET_FH_SELECTED_SUB_CATE', null);
  },
  FETCH_SUB_CATEGORIES_WITH_DEST: async (context) => {
    context.commit('SET_LOADING_STATE', true);
    const response = await get('BFF', '/packSubCategory/withDest', context);
    context.commit('SET_FH_SUB_CATEGORIES', response.data);
    context.commit('SET_LOADING_STATE', false);
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

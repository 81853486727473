/* eslint-disable no-shadow */
import {
  get,
  // post,
  // postWithText,
  // cancelGetRequest,
} from '@/utils/api';
// import controllerHome from '@/utils/regular/controllerHome';
const { VUE_APP_LANGUAGES_LIST } = process.env;
const languageList = JSON.parse(VUE_APP_LANGUAGES_LIST);

const state = {
  subjects: [],
  dealTypes: null,
  hasDealType: null,
  dealItinerary: null,

  imagesEvent: [],
};

const mutations = {
  SET_SUBJECTS: (state, payload) => {
    state.subjects = payload;
  },
  SET_DEAL_TYPE: (state, payload) => {
    state.dealTypes = payload;
  },
  SET_HAS_DEAL_TYPE: (state, payload) => {
    state.hasDealType = payload;
  },
  SET_DEAL_ITINERARY: (state, payload) => {
    state.dealItinerary = payload;
  },

  SET_IMAGES_EVENT: (state, payload) => {
    state.imagesEvent = payload;
  },
};

const actions = {
  FETCH_SUBJECTS: async (context) => {
    context.commit('SET_LOADING_STATE', true);
    const response = await get('BFF', '/subject/flatten?from=1&to=10000', context);
    const data = await response.data;
    context.commit('SET_SUBJECTS', data.data);
    context.commit('SET_LOADING_STATE', false);
    return data;
  },
  FETCH_DEAL_TYPE_LIST: async (context) => {
    const response = await get('BFF', `/operation/dealType?langCode=${languageList[context.rootState.language]}`, context);
    context.commit('SET_DEAL_TYPE', response.data);
    return response;
  },
  FETCH_HAS_DEAL_TYPE_LIST: async (context) => {
    const response = await get('BFF', `/deal/inEffectHome/dealTypes?langCode=${languageList[context.rootState.language]}`, context);
    context.commit('SET_HAS_DEAL_TYPE', response.data);
    return response;
  },
  FETCH_DEAL_ITINERARY: async (context) => {
    const response = await get('BFF', '/itinerary', context);
    context.commit('SET_DEAL_ITINERARY', response.data);
    return response;
  },

  FETCH_IMAGES_EVENT: async (context) => {
    context.commit('SET_LOADING_STATE', true);
    const response = await get('BFF', '/footballData/competition', context);
    const data = await response.data;
    context.commit('SET_IMAGES_EVENT', data);
    context.commit('SET_LOADING_STATE', false);
  },
};

const getters = {
  GET_SUBJECTS: (state) => state.subjects,
  GET_DEAL_TYPE: (state) => state.dealTypes,
  GET_HAS_DEAL_TYPE: (state) => state.hasDealType,
  GET_DEAL_ITINERARY: (state) => state.dealItinerary,

  GET_IMAGES_EVENT: (state) => state.imagesEvent,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
